.headroom--above-the-fold:not(.headroom--near-the-fold) body[data-template=home] .page__header .d-none .search.search--preview{
    @include media-breakpoint-down(xxl) {
        display: none;
    }
}
.quicklinks{
    @include media-breakpoint-down(lg) {
        z-index: 99;
        position: relative;
    }
}
body{
    .banner--fullscreen + .container-xxl{
        height: 0;
        padding-top: 0!important;
        padding-bottom: 0!important;
        @media(max-width: 1100px){
            height: auto;
        }
        @include media-breakpoint-down(md) {
            background-color: $color__white;
            padding-top: 40px!important;
            padding-bottom: 40px!important;
            z-index: 101;
            position: relative;
            .navigation-open & {
                z-index: 1;
            }
        }
        >.row{
            justify-content: flex-start!important;
            position: relative;
            >.col{
                width: 100%!important;
                position: absolute;
                left: 0;
                width: 100%;
                bottom: 80px;
                z-index: 999;
                @media(max-width: 1400px){
                    bottom: 50px;
                }
                @media(max-width: 992px){
                    .meta-navigation__item a{
                        padding: 25px;
                    }
                }
                @include media-breakpoint-down(md) {
                    position: relative;
                    bottom: 0;
                }
                @include media-breakpoint-down(sm) {
                    span[aria-hidden="true"]{
                        font-size: 13px;
                    }
                    
                }
            }
        }
        .meta-navigation__nav{
            background-color:  transparent;
            padding-right: 300px;
            @media(max-width: 1100px){
                padding-right: 0;
            }
        }
        .meta-navigation__list{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            @media(max-width: 1100px){
                flex-wrap: wrap;
            }

        }
        .meta-navigation__item{
            padding: 0 2px;
            width: auto!important;
            @media(max-width: 767px){
                width: 50%!important;

            }
            a{
                border: none;
                padding: 32px;
                display: flex;
                flex-direction: column;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                span.icon{
                    img{
                        height: 40px;
                        filter: $filter__black
                    }
                }
                span+span.icon{
                    display: none
                }
                @include hover-focus-visible{
                    background-color: $color__primary;
                    color: $color__white;
                    span.icon{
                        filter: $filter__white;
                    }
                }
            }
        }
    }
    .page__header{
        >.container-fluid{
            padding-right: 170px!important;
        }
    }
}


body {
    &.navigation-open,
    &.c-gufi-preview-wrapper-active{
        .banner--fullscreen+.container-xxl>.row>.col{
            z-index: 1;
        }
    }
}