.page {
    &__header {
        position: relative;
        @include container-child-combinator {
            &:first-of-type {
                // padding-right: $header__height--mobile + 100px;
                @include media-breakpoint-up(lg) {
                    // padding-right: $header__height--unpinned + 100px;
                }
                @include media-breakpoint-up(xxl) {
                    // padding-right: $header__height + 100px;
                }
                html.headroom--unpinned & {
                    @include media-breakpoint-up(xxl) {
                        // padding-right: $header__height--unpinned + 100px;
                    }
                }
            }
        }
    }
}
.page__footer .meta-navigation--accessibility .meta-navigation__item a{
    text-decoration: none;
}
