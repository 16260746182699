body .splide:not(.splide--custom) .splide__pagination button{
    @include media-breakpoint-down(lg) {
        color: $color__black;
    }
}
body .splide:not(.splide--custom) .splide__pagination button.is-active{
    @include media-breakpoint-down(lg) {
        color: $color__primary;
    }
}
