html body.has-banner-slider{
    .banner.banner--video,
    .banner--slider.banner--fullscreen{
        padding-top: 0;
        figure{
            height: calc(100vh - #{$header__height--mobile} - 160px);
            height: calc((var(--vh, 1vh) * 100) - #{$header__height--mobile} - 160px); // less direct links teaser
            overflow: hidden;

            @include media-breakpoint-up(lg) {
                height: calc(100vh - #{$header__height--unpinned} - 160px);
                height: calc((var(--vh, 1vh) * 100) - #{$header__height--unpinned} - 160px);
            }

            @include media-breakpoint-up(xxl) {
                height: calc(100vh - #{$header__height} - 160px);
                height: calc((var(--vh, 1vh) * 100) - #{$header__height} - 160px);
            }
        }
        .banner__footer{
            position: relative;
            bottom: 0;
            &:before{
                content: "";
                position: absolute;
                left: -10px;
                right:-10px;
                top: 1px;
                height: auto;
                aspect-ratio: 1920/60;
                background-image: url(/assets/website/img/welle.svg);
                background-repeat: no-repeat;
                background-size: cover;
                transform: translateY(-100%);
                z-index: 1;
            }
            .container-xxl{
                position: relative;
                height: 50px;
                background-color: $color__white;
                .splide__navigation{
                    position: absolute;
                    right: 64px;

                }
            }

            .splide__toggle{
                background-color: $color__white;
                padding: 7px;
                margin: 0;
                @include transition;
                    span img{
                        width: 30px;
                        height: 30px;
                        filter: $filter__black;
                        @include transition;
                    }
                @include hover-focus-visible{
                    background-color: $color__primary;
                    span img{
                        filter: $filter__white
                    }
                }
            }
            .splide__pagination{
                gap: 0 10px;
                &__page{
                    width: 20px;
                    height: 20px;
                    &:before{
                        width: 10px;
                        height: 10px;
                        border-radius: 5px;
                        background-color: $color__black;
                        @include transition
                    }
                    &:after{
                          content: "";
                          width: 8px;
                          height: 8px;
                          border-radius: 15px;
                          border: 0px solid $color__primary;
                          background-color: transparent;
                          @include transition;
                          position: absolute;
                          left: 50%;
                          top: 50%;
                          transform: translate(-50%,-50%);

                    }
                    @include hover-focus-visible{
                        &:before{
                            background-color: $color__primary;
                        }
                    }
                    &.is-active{
                        &:before{
                            background-color: $color__primary;
                        }
                        &::after{
                            width: 27px;
                            height: 27px;
                            border-width: 2px;
                        }

                    }
                }

            }
            .splide__arrows{
                gap: 0 5px;
                flex: unset;
                margin-right: 25px;
            }
            .splide__arrow{
                margin: 0;
                padding: 10px 5px;
                background-color: $color__white;
                @include transition;
                &--next,
                &--prev{
                    span img{
                        height: 30px;
                        width: 30px;
                        filter: $filter__black;
                        @include transition;
                    }
                    @include hover-focus-visible{
                        background-color: $color__primary;
                        span img{
                            filter: $filter__white
                        }
                    }
                }
                &--prev{
                    span img{
                        transform: rotate(180deg);
                    }
                }
            }
        }

    }
}
.banner--image{
    position: relative;
    &:before{
        content: "";
        position: absolute;
        left: -10px;
        right: -10px;
        bottom: -1px;
        // width: 100%;
        height: auto;
        aspect-ratio: 1920/60;
        background-image: url(/assets/website/img/welle.svg);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
    }
}
.meta-navigation--crumb{

    padding-top: 30px;
    .has-banner-image &{
        padding-top: 0;
    }
    .meta-navigation__item{
        &:not(:last-child){
            gap: 0;

            body &{
                .meta-navigation__link{
                    color: $color__primary;
                }
                &::after{
                    width: 18px;
                    margin: 0 5px;
                    background-image: url('/assets/website/img/arrow.svg');
                }
            }
        }

    }
}
body[data-editmode='true'] {
    .quicklinks .meta-navigation__nav{
        left: 225px;
    }
}
