body[data-template="home"]{
    &.has-breaking-ribbon{
        padding-top: 64px;
        >.page{
            overflow: initial;
            .breaking-ribbon{
                top: -64px;
            }
        }
    }
}
