@import "../main/abstracts/index";
.content-navigation--dynamic,
.content--content-navigation,
.content--dynamic-content-navigation {
    .content-navigation{
        &__item{
            width: 100%;
            height: auto;
            aspect-ratio: 1;
            overflow: hidden;
            border: none;
            border-radius: 0;
            &:not(.content-navigation__item--has-image),
            &:not(.content-navigation__item--has-preview-image){
                background-color: $color__primary;
                .content-navigation__body{
                    position: absolute;
                    left: 0;
                    right: 0;
                }
                >figure{
                    border: none;
                    padding-top: 0;
                    position: unset;
                    border-radius: 0;
                    background-color: transparent;
                    height: 100%!important;
                    img{
                        filter: $filter__white;
                        left: unset;
                        right: 0;
                        bottom: 0;
                        transform: translate(0);
                    }
                }
                @include hover-focus-visible-within{
                    p{
                        &[aria-hidden="true"]:before{
                            border: 1px solid $color__white;
                        }
                    }
                }
            }
            @include hover-focus-visible-within{
                p{
                    &[aria-hidden="true"]:before{
                        border: 1px solid $color__white;
                    }
                }
            }
            >figure{
                border: none;
                padding-top: 0;
                position: unset;
                img{
                    position: absolute;
                    left: unset;
                    right: 0;
                    top: unset;
                    bottom: 0;
                    transform: translate(0);
                    width: 50%;
                }
            }
            @include hover-focus-visible-within{
                .content-navigation__body{
                    h3 a{
                        color: $color__primary;
                    }
                    >.justify-content-end{
                        &:before{
                            background-color: $color__primary;
                            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                        }
                        img{
                            transform: translate(0);
                        }
                    }
                }
            }
            &.content-navigation__item--has-image,
            &.content-navigation__item--has-preview-image{

                &>figure img {
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    filter: none;
                }
                @include hover-focus-visible-within{
                    &>figure img {
                        transform: scale(1.1);
                    }
                    p{
                        &[aria-hidden="true"]:before{
                            border: 0px solid $color__white;
                        }
                    }
                }
            }
        }
        &__body{
            padding: 25px!important;
            z-index: 1;
            margin: 10px;
            flex: none;
            border: none;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            >.justify-content-end{
                position: relative;
                padding: 0!important;
                &:before{
                    content: "";
                    width: 40px;
                    height: 40px;
                    border-radius: 4px;
                    background-color: $color__black;
                    position: absolute;
                    right: 0;
                    bottom: -45px;

                }
                img{
                    position: absolute;
                    right: 10px;
                    bottom: -32px;
                    filter: $filter__white;
                    transform: translate(0);
                }
            }
        }
    }
}

.content-navigation__item.content-navigation__item--has-link:hover p[aria-hidden=true] img, .content-navigation__item.content-navigation__item--has-link.focus-within p[aria-hidden=true] img{
    transform: none!important;
}
body .content-navigation__item:not(.content-navigation__item--has-image):not(.content-navigation__item--has-preview-image) figure{
    padding-top: 0;
}
body .content-navigation__item:not(.content-navigation__item--has-image):not(.content-navigation__item--has-preview-image) figure>img{
    top: unset;
    left: unset;
    transform: translate(0);
    filter: $filter__white;
}