.off-canvas__toggle--open {
    html.headroom--above-the-fold:not(.headroom--near-the-fold) body[data-template="home"] & {
        width: 160px;
        top: 15px;
        position: absolute;

        @include media-breakpoint-down(md) {
            width: 110px;
            height: 42px;
        }
    }

    html.headroom--unpinned body &,
    html.js body & {
        width: 160px;
        position: fixed;
        top: 0;
        z-index: 101;

        @include media-breakpoint-down(md) {
            width: 72px;
        }
    }
}

.off-canvas__trigger:checked+.off-canvas__wrapper .off-canvas__toggle--off {
    @include media-breakpoint-down(lg) {
        z-index: 100;
    }
}

.off-canvas__trigger:checked+.off-canvas__wrapper .off-canvas__inner {
    z-index: 102;

}

.quicklinks .meta-navigation__nav {
    .navigation-open & {
        z-index: 1;
    }

}
body .off-canvas__search{
    @media(max-width: 400px){
        padding-left: 0;
    }
}
.off-canvas__footer {
    html.js & {
        height: 7rem;
        gap: 2rem;
        a span {
            &:not(.icon) {
                @include media-breakpoint-down(lg) {
                    position: absolute;
                    height: 1px;
                    width: 1px;
                    overflow: hidden;
                    clip-path: inset(50%);
                    white-space: nowrap;
                }

            }
        }
    }
}
html.js body .off-canvas--has-footer .main-navigation{
    max-height: calc(100% - 7rem);
}
body .off-canvas__trigger:checked+.off-canvas__wrapper .off-canvas__toggle--off {
    z-index: 102;
}

body .off-canvas__footer {
    a.button {
        background-color: $color__white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        flex: 1;
        align-items: center;
        display: flex;
        justify-content: center;

        span {
            &.icon {
                img {
                    height: 30px;
                    filter: $filter__black
                }

            }

            &:not(.icon) {
                display: none;
            }
        }
        @include hover-focus-visible-within{
            background-color: $color__primary;
            span {
                &.icon {
                    img {
                        height: 30px;
                        filter: $filter__white
                    }
    
                }
            }
        }
    }
}

html.js body {

    li.main-navigation__item.main-navigation__item--home,
    li.main-navigation__item.main-navigation__item--back {
        background-color: $color__gray--light;
        margin: 0 -25px;
        color: black;
        button,
        a {
            color: $color__black;
        }
    }
    li.main-navigation__item.main-navigation__item--header{
        padding-bottom: 0;
        padding-top: 24px;
        span{
            padding-left: 30px;
        }
    }
    li.main-navigation__item.main-navigation__item--back{
        padding: 0 25px 0 55px;
    }  
 
    li.main-navigation__item.main-navigation__item--home {
        a::before {
            background-image: url(/assets/website/img/home.svg);
            filter: unset;
        }
        text-decoration: none;
        @include hover-focus-visible{
            text-decoration: underline;
        }

    }

    .main-navigation__button {
        
        &.main-navigation__button--prev{
            text-decoration: none;
            &>span[aria-hidden] {
                height: 40px;
                width: 40px;
                background-color: $color__black;
                border-radius: 4px;
                margin-top: 10px;
                margin-right: 13px;
                position: relative;
                filter: none;
                
                &:before {
                    content: "";
                    width: 18px;
                    height: 12px;
                    position: absolute;
                    z-index: 2;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(180deg);
                    background-image: url(/assets/website/img/arrow.svg);
                    background-position: center;
                    background-size: contain;
                    filter: $filter__white;
                }
            }
            @include hover-focus-visible-within{ 
                text-decoration: underline;
                &>span[aria-hidden] {
                    background-color: $color__primary;
                    background-image: none;
                }
            }
        }

        @include hover-focus-visible-within {
            background-position: 100% 100%, 0 0 !important;

            .main-navigation__link>span[aria-hidden] {
                background-color: $color__primary;
                filter: none;
            }

            .main-navigation__link>span:not([aria-hidden]) {
                text-decoration: underline;
            }
        }
    }
    .main-navigation__button:not(.main-navigation__button--prev), 
    .main-navigation__link {
        font-weight: 400;
        padding-left: 60px;
        padding-right: 30px;
        @include media-breakpoint-down(sm) {
            padding-left: 40px;
            
        }

        &>span[aria-hidden] {
            background-image: unset;
            background-position: center center;
            position: relative;
            width: 40px;
            height: 40px;
            background-color: $color__black;
            border-radius: 4px;
            overflow: hidden;
            margin-top: 10px;
            filter: none;

            &:before {
                content: "";
                width: 18px;
                height: 12px;
                position: absolute;
                z-index: 2;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background-image: url(/assets/website/img/arrow.svg);
                background-position: center;
                background-size: contain;
                filter: $filter__white;
            }
        }

        @include hover-focus-visible-within {
            background-position: 100% 100%, 0 0 !important;

            &>span:not([aria-hidden]) {
                text-decoration: underline;
            }
            &>span[aria-hidden] {
                background-color: $color__primary;
                filter: none;
            }
        }
        >.main-navigation__link{
            padding: 0;
        }
    }
}