.content--image-slider{
    .splide:not(.splide--custom){
        .splide__toggle{
            background-color: $color__white;
            padding: 7px;
            margin: 0;
            @include transition;
                span img{
                    width: 30px;
                    height: 30px;
                    filter: $filter__black;
                    @include transition;
                }
            @include hover-focus-visible{
                background-color: $color__primary;
                span img{
                    filter: $filter__white
                }
            }
        }
        .splide__pagination{
            gap: 0 10px;
            &__page{
                width: 20px;
                height: 20px;
                &:before{
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;
                    background-color: $color__black;
                    @include transition
                }
                &:after{
                      content: "";
                      width: 8px;
                      height: 8px;
                      border-radius: 15px;
                      border: 0px solid $color__primary;
                      background-color: transparent;
                      @include transition;
                      position: absolute;
                      left: 50%;
                      top: 50%;
                      transform: translate(-50%,-50%);

                }
                @include hover-focus-visible{
                    &:before{
                        background-color: $color__primary;
                    }
                }
                &.is-active{
                    width: 20px;
                    &:before{
                        background-color: $color__primary;
                    }
                    &::after{
                        width: 27px;
                        height: 27px;
                        border-width: 2px;
                    }

                }
            }

        }
        .splide__arrows{
            gap: 0 5px;
            flex: unset;
            margin-right: 25px;
        }
        .splide__arrow{
            margin: 0;
            padding: 10px 5px;
            background-color: $color__white;
            @include transition;
            &--next,
            &--prev{
                span img{
                    height: 30px;
                    width: 30px;
                    filter: $filter__black;
                    @include transition;
                }
                @include hover-focus-visible{
                    background-color: $color__primary;
                    span img{
                        filter: $filter__white
                    }
                }
            }
            &--prev{
                span img{
                    transform: rotate(180deg);
                }
            }
        }
        .splide__navigation{
            justify-content: center;
            &:after{
                display: none;
            }
        }
    }

}
